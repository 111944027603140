@import "../../utils/reusable.scss";

.Contact {
  @extend %screen-set;

  .contact {
    @extend %divs-padding-set;

    .form-section {
      padding-top: 30px;
      h2 {
        font-size: 25px;
        color: #000;
        font-weight: 400;
        @include small-screen {
          text-align: center;
        }
      }
      form {
        position: relative;

        padding-top: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        input {
          padding: 10px;
          width: 60%;
          border: 1px solid #d1d1d1;
          border-radius: 4px;
          outline: none;
          transition-property: all;
          transition-duration: 0.3s;
          @include tablet {
            width: 100%;
          }

          &:focus {
            border: 1px solid $light-blue;
          }
        }
        .form-elem {
          label {
            color: #686868;
            font-weight: lighter;
          }
        }

        textarea {
          transition-property: all;
          transition-duration: 0.3s;
          width: 60%;
          height: 255px;
          padding: 10px;
          border: 1px solid #d1d1d1;
          border-radius: 4px;
          outline: none;

          @include tablet {
            width: 100%;
          }

          &:focus {
            border: 1px solid $light-blue;
          }
        }
        button {
          transition-property: all;
          transition-duration: 0.3s;
          padding: 10px;
          background-color: $light-blue;
          color: #fff;
          font-size: 16px;
          font-weight: bolder;
          align-self: stretch;
          outline: none;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          width: 60%;
          @include tablet {
            width: 100%;
          }

          &:active {
            background-color: green;
          }
        }
        .image-container {
          display: none;
          width: 60%;
          text-align: center;
          @include tablet {
            width: 100%;
          }
          img {
            width: 50px;
          }
        }
        .bring-it-back {
          display: inline-block;
        }
      }
    }
  }

  .show {
    height: 180px;
  }

  .invalid {
    border-color: red !important;
    box-shadow: 1px 1px 2px red, -1px -1px 2px red;
  }
}

.Toastify__toast-body {
  text-align: center;
  font-size: 20px;
}
