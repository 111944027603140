@mixin medium-screen {
  @media only screen and (max-width: 1120px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 940px) {
    @content;
  }
}

@mixin small-screen {
  @media only screen and (max-width: 795px) {
    @content;
  }
}

@mixin smaller-screen {
  @media only screen and (max-width: 390px) {
    @content;
  }
}
