@import "../../utils/reusable.scss";

.Portfolio {
  @extend %screen-set;

  .my-portfolio {
    @extend %divs-padding-set;

    .projects {
      padding-top: 30px;
      h2 {
        font-size: 25px;
        color: #000;
        text-align: center;
        font-weight: 400;
      }
      .cards {
        padding-top: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 8px;
        .none {
          display: none;
        }
        @include tablet {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        .project {
          position: relative;
          h3 {
            color: $light-blue;
            font-weight: bolder;
            text-align: center;
            cursor: pointer;
            a {
              span {
                color: #ed4337;
                font-weight: bold;
              }
              color: $light-blue;
              text-decoration: none;
              &:visited {
                color: $light-blue;
              }
            }
          }

          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          width: 45%;
          padding: 8px 8px;
          row-gap: 2px;
          @include tablet {
            width: 100%;
          }
          .project-image {
            .image-link:active {
              img {
                opacity: 0.7;
              }
            }
            cursor: pointer;
            position: relative;
            .overlay-link {
              .overlay {
                visibility: hidden;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0.6;
                z-index: 900;
                transition-property: all;
                transition-duration: 0.3s;
              }
              @include medium-screen {
                & {
                  display: none;
                }
              }
            }

            a {
              text-decoration: none;
            }

            a {
              button {
                visibility: hidden;
                position: absolute;
                left: 0%;
                top: 50%;
                transform: translate(-50%, -50%) rotateX(90deg);
                z-index: 950;
                cursor: pointer;
                border: none;
                outline: none;
                padding: 6px;
                font-size: 18px;
                background-color: $light-blue;
                color: $light-gray;
                font-weight: bold;
                transition-property: all;
                transition-duration: 0.2s;
                transition-timing-function: ease-out;
                overflow: hidden;
                &:hover:enabled {
                  color: $light-blue;
                  background-color: #000;
                }
                @include medium-screen {
                  display: none;
                }
              }
            }

            &:hover {
              .overlay {
                background-color: #000;
                visibility: visible;
              }

              a {
                button {
                  visibility: visible;
                  left: 50%;
                  transform: translate(-50%, -50%) rotateX(0deg);
                }
              }
            }
            a img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .show {
    height: 180px;
  }
}
