@import "../../utils/colors.scss";
@import "../../utils/media-queries.scss";
@import "../../utils/reusable.scss";

.Activities {
  padding-top: 50px !important;
  color: $medium-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-container {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;

    .fas {
      font-size: 55px;
    }

    h3 {
      position: relative;
      color: transparent;
      font-size: 30px;
      font-weight: normal;
    }

    h3::before {
      /* This will create the layer 
           over original text*/
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      color: $light-blue;
      overflow: hidden;
      width: 0%;
    }

    .animate-EAT::before {
      animation: fill-up 0.3s ease-in-out 0.5s 1 forwards,
        vanish 0.5s linear 4s 1 forwards;
    }
    .animate-SLEEP::before {
      animation: fill-up 0.7s ease-in-out 0.8s 1 forwards,
        vanish 0.5s linear 4s 1 forwards;
    }
    .animate-CODE::before {
      animation: fill-up 0.7s ease-in-out 1.5s 1 forwards,
        vanish 0.5s linear 4s 1 forwards;
    }
    .animate-BASKETBALL::before {
      animation: fill-up 0.7s ease-in-out 2.2s 1 forwards,
        vanish 0.5s linear 4s 1 forwards;
    }
    .animate-REPEAT::before {
      animation: fill-up 0.7s ease-in-out 2.9s 1 forwards,
        vanish 0.5s linear 4s 1 forwards;
    }
  }

  @include medium-screen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    .icon-container:nth-of-type(5) {
      grid-column: 1/3;
    }
  }
  @include small-screen {
    padding-top: 10px;
    .icon-container {
      min-width: 110px;

      padding: 10px 18px;
    }
  }

  @include smaller-screen {
    .icon-container {
      .fas {
        font-size: 43px;
      }
      h3 {
        font-size: 25px;
      }
    }
  }
}
